import React, { useEffect } from 'react';
import { useAppDispatch } from '@hooks';
import { authCheckState } from '../state/actions/authentication/authenticationAction';
import '../scss/App.scss';

// projects activity chat settings
const Index: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.info('onTryAutoSignIn');
    dispatch(authCheckState());
  }, [dispatch]);

  return null;
};

// noinspection JSUnusedGlobalSymbols used by Gatsby
export default Index;
